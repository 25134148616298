import React from 'react';
import { graphql } from 'gatsby';

import * as PAGES from '../constants/pages';
import Layout from '../components/Layout';
import Blog from '../components/Blog';

import { container } from './blog.module.scss';

const mergePosts = (allPosts, relevantPostIds) =>
  relevantPostIds.map((id) =>
    allPosts.edges.find((edge) => edge.node.id === id)
  );

export default function BlogPage({
  location,
  data: { allPosts },
  pageContext: { postsByCategories },
}) {
  return (
    <Layout location={location} page={PAGES.BLOG}>
      {() => (
        <div className={container}>
          {postsByCategories
            .filter((postsByCategory) => postsByCategory.postIds.length > 0)
            .map((postsByCategory) => {
              const [featuredPost, ...otherPosts] = mergePosts(
                allPosts,
                postsByCategory.postIds
              );

              return (
                <Blog
                  key={postsByCategory.category}
                  featuredPost={featuredPost}
                  posts={otherPosts}
                  header={{
                    prefix: postsByCategory.prefix,
                    suffix: postsByCategory.suffix,
                    label: postsByCategory.label,
                    slug: postsByCategory.slug,
                  }}
                />
              );
            })}
        </div>
      )}
    </Layout>
  );
}

export const query = graphql`
  query Blog($postIds: [String]) {
    allPosts: allMdx(filter: { id: { in: $postIds } }) {
      edges {
        node {
          id
          excerpt(pruneLength: 350)
          frontmatter {
            title
            banner {
              childImageSharp {
                fluid(maxWidth: 500) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          parent {
            ... on File {
              relativePath
            }
          }
        }
      }
    }
  }
`;
